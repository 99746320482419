@font-face {
  font-family: "Unicons";
  src: url("./fonts/unicons-line.woff2") format("woff2"),
    url("./fonts/unicons-line.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: "Space Grotesk";
  src: url("./fonts/SpaceGrotesk-SemiBold.woff2") format("woff2"),
    url("./fonts/SpaceGrotesk-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: "Space Grotesk";
  src: url("./fonts/SpaceGrotesk-Bold.woff2") format("woff2"),
    url("./fonts/SpaceGrotesk-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: "Space Grotesk";
  src: url("./fonts/SpaceGrotesk-Light.woff2") format("woff2"),
    url("./fonts/SpaceGrotesk-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: "Space Grotesk";
  src: url("./fonts/SpaceGrotesk-Medium.woff2") format("woff2"),
    url("./fonts/SpaceGrotesk-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: "Space Grotesk";
  src: url("./fonts/SpaceGrotesk-Regular.woff2") format("woff2"),
    url("./fonts/SpaceGrotesk-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: "Gilroy Medium";
  src: url("./fonts/Gilroy-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Gilroy Bold";
  src: url("./fonts/Gilroy-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "MATERIAL ICONS";
  src: url("./fonts/MaterialIconsRound-Regular.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
  font-display: block;
}

* {
  font-family: Gilroy Medium, sans-serif;
  scroll-behavior: smooth;
}
strong {
  font-family: Gilroy Bold, sans-serif;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                      supported by Chrome, Edge, Opera and Firefox */
}

.ant-message {
  z-index: 2000;
}

.ant-btn-primary {
  background-color: #294895 !important;
  border: 1px solid #294895;
  &:hover {
    color: #294895 !important;
    background-color: rgba(0, 0, 0, 0) !important;
  }
}

.ant-btn.ant-btn-primary:not(.ant-btn-dangerous) {
  background-color: #294895;
}
button.ant-switch {
  background-color: rgba(0, 0, 0, 0.25);
}
