.dashboard-wrapper {
  .page-wrapper {
    position: sticky;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-image: url("./images/Logo_stacked_white.png");
    background-position: 50vw 50vh;
    background-repeat: no-repeat;
    background-size: auto;
  }

  .w-layout-grid {
    grid-row-gap: 16px;
    grid-column-gap: 16px;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
    display: grid;
  }

  .w-checkbox {
    margin-bottom: 5px;
    padding-left: 20px;
    display: block;
  }

  .w-checkbox:before {
    content: " ";
    grid-area: 1 / 1 / 2 / 2;
    display: table;
  }

  .w-checkbox:after {
    content: " ";
    clear: both;
    grid-area: 1 / 1 / 2 / 2;
    display: table;
  }

  .w-checkbox-input {
    float: left;
    margin: 4px 0 0 -20px;
    line-height: normal;
  }

  .w-checkbox-input--inputType-custom {
    width: 12px;
    height: 12px;
    border: 1px solid #ccc;
    border-radius: 2px;
  }

  .w-checkbox-input--inputType-custom.w--redirected-checked {
    background-color: #3898ec;
    background-image: url("https://d3e54v103j8qbb.cloudfront.net/static/custom-checkbox-checkmark.589d534424.svg");
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    border-color: #3898ec;
  }

  .w-checkbox-input--inputType-custom.w--redirected-focus {
    box-shadow: 0 0 3px 1px #3898ec;
  }

  .w-form-formradioinput--inputType-custom {
    width: 12px;
    height: 12px;
    border: 1px solid #ccc;
    border-radius: 50%;
  }

  .w-form-formradioinput--inputType-custom.w--redirected-focus {
    box-shadow: 0 0 3px 1px #3898ec;
  }

  .w-form-formradioinput--inputType-custom.w--redirected-checked {
    border-width: 4px;
    border-color: #3898ec;
  }

  body {
    color: #000;
    letter-spacing: -0.03em;
    font-family: Inter, sans-serif;
    font-size: 1rem;
    line-height: 1.5;
  }

  h1 {
    letter-spacing: 0;
    margin-top: 0;
    margin-bottom: 0;
    font-family: Gilroy Bold, sans-serif;
    font-size: 4rem;
    font-weight: 700;
    line-height: 1.1;
  }

  h2 {
    letter-spacing: 0;
    margin-top: 0;
    margin-bottom: 0;
    font-family: Gilroy Bold, sans-serif;
    font-size: 3rem;
    font-weight: 700;
    line-height: 1.2;
  }

  h3 {
    letter-spacing: 0;
    margin-top: 0;
    margin-bottom: 0;
    font-family: Gilroy Bold, sans-serif;
    font-size: 2rem;
    font-weight: 700;
    line-height: 1.2;
  }

  h4 {
    letter-spacing: 0;
    margin-top: 0;
    margin-bottom: 0;
    font-family: Gilroy Bold, sans-serif;
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 1.4;
  }

  h5 {
    letter-spacing: 0;
    margin-top: 0;
    margin-bottom: 0;
    font-family: Gilroy Bold, sans-serif;
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.5;
  }

  h6 {
    letter-spacing: 0;
    margin-top: 0;
    margin-bottom: 0;
    font-family: Gilroy Bold, sans-serif;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5;
  }

  p {
    margin-bottom: 0;
  }

  ul,
  ol {
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 1.5rem;
  }

  li {
    margin-bottom: 0.25rem;
  }

  img {
    max-width: 100%;
    display: inline-block;
  }

  label {
    margin-bottom: 0.25rem;
    font-weight: 500;
  }

  blockquote {
    border-left: 0.25rem solid #e2e2e2;
    margin-bottom: 0;
    padding: 0 1.25rem;
    font-size: 1.25rem;
    line-height: 1.5;
  }

  figure {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  figcaption {
    text-align: center;
    margin-top: 0.25rem;
  }

  .utility-page_component {
    width: 100vw;
    height: 100vh;
    max-height: 100%;
    max-width: 100%;
    justify-content: center;
    align-items: center;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    display: flex;
  }

  .utility-page_wrapper {
    max-width: 20rem;
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
    text-align: center;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    display: flex;
  }

  .utility-page_form {
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    display: flex;
  }

  .utility-page_image {
    margin-left: auto;
    margin-right: auto;
  }

  .global-styles {
    display: block;
    position: fixed;
    top: 0%;
    bottom: auto;
    left: 0%;
    right: auto;
  }

  .margin-custom2 {
    margin: 2.5rem;
  }

  .padding-xlarge {
    padding: 4rem;
  }

  .margin-xlarge {
    margin: 4rem;
  }

  .margin-xsmall {
    margin: 0.5rem;
  }

  .padding-xhuge {
    padding: 8rem;
  }

  .margin-custom1 {
    margin: 1.5rem;
  }

  .padding-0 {
    padding: 0;
  }

  .padding-xxhuge {
    padding: 12rem;
  }

  .padding-huge {
    padding: 6rem;
  }

  .margin-large {
    margin: 3rem;
  }

  .padding-xxlarge {
    padding: 5rem;
  }

  .margin-xxsmall {
    margin: 0.25rem;
  }

  .padding-custom3 {
    padding: 3.5rem;
  }

  .padding-large {
    padding: 3rem;
  }

  .margin-tiny {
    margin: 0.125rem;
  }

  .padding-small {
    padding: 1rem;
  }

  .padding-custom2 {
    padding: 2.5rem;
  }

  .margin-custom3 {
    margin: 3.5rem;
  }

  .padding-custom1 {
    padding: 1.5rem;
  }

  .margin-huge {
    margin: 6rem;
  }

  .padding-medium {
    padding: 2rem;
  }

  .padding-xsmall {
    padding: 0.5rem;
  }

  .margin-xxlarge {
    margin: 5rem;
  }

  .padding-xxsmall {
    padding: 0.25rem;
  }

  .margin-xhuge {
    margin: 8rem;
  }

  .padding-tiny {
    padding: 0.125rem;
  }

  .margin-small {
    margin: 1rem;
  }

  .margin-medium {
    margin: 2rem;
  }

  .margin-xxhuge {
    margin: 12rem;
  }

  .margin-0 {
    margin: 0;
  }

  .margin-horizontal {
    margin-top: 0;
    margin-bottom: 0;
  }

  .padding-top {
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
  }

  .margin-vertical {
    margin-left: 0;
    margin-right: 0;
  }

  .margin-bottom {
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
  }

  .padding-left {
    padding-top: 0;
    padding-bottom: 0;
    padding-right: 0;
  }

  .padding-vertical {
    padding-left: 0;
    padding-right: 0;
  }

  .padding-horizontal {
    padding-top: 0;
    padding-bottom: 0;
  }

  .margin-right {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 0;
  }

  .margin-top {
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
  }

  .margin-left {
    margin-top: 0;
    margin-bottom: 0;
    margin-right: 0;
  }

  .padding-right {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
  }

  .padding-bottom {
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
  }

  .form_checkbox {
    flex-direction: row;
    align-items: center;
    margin-bottom: 0.5rem;
    padding-left: 0;
    display: flex;
  }

  .form_checkbox-icon {
    width: 0.875rem;
    height: 0.875rem;
    border-radius: 0.125rem;
    margin: 0 0.5rem 0 0;
  }

  .form_checkbox-icon.w--redirected-checked {
    width: 0.875rem;
    height: 0.875rem;
    background-size: 90%;
    border-radius: 0.125rem;
    margin: 0 0.5rem 0 0;
  }

  .form_checkbox-icon.w--redirected-focus {
    width: 0.875rem;
    height: 0.875rem;
    border-radius: 0.125rem;
    margin: 0 0.5rem 0 0;
    box-shadow: 0 0 0.25rem 0 #3898ec;
  }

  .fs-styleguide_background {
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.1);
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    display: flex;
  }

  .fs-styleguide_spacing {
    grid-column-gap: 0.5rem;
    grid-row-gap: 0.5rem;
    background-image: linear-gradient(
      to top,
      rgba(0, 115, 230, 0.1),
      rgba(255, 255, 255, 0)
    );
    grid-template-rows: auto auto;
    grid-template-columns: 1fr;
    grid-auto-columns: 1fr;
    align-content: start;
    justify-content: start;
    align-items: start;
    justify-items: stretch;
    display: grid;
    position: relative;
  }

  .icon-1x1-small {
    width: 1rem;
    height: 1rem;
    flex: none;
  }

  .overflow-auto {
    overflow: auto;
  }

  .spacing-clean {
    margin: 0;
    padding: 0;
  }

  .icon-1x1-large {
    width: 2.5rem;
    height: 2.5rem;
  }

  .z-index-2 {
    z-index: 2;
    position: relative;
  }

  .fs-styleguide_background-space {
    width: 1px;
    height: 1px;
    margin: 5rem;
  }

  .text-weight-semibold {
    font-weight: 600;
  }

  .text-style-strikethrough {
    text-decoration: line-through;
  }

  .fs-styleguide_item {
    grid-column-gap: 1.125rem;
    grid-row-gap: 1.125rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    grid-template-rows: auto;
    grid-template-columns: 1fr;
    grid-auto-columns: 1fr;
    align-content: start;
    justify-content: start;
    align-items: start;
    justify-items: start;
    padding-bottom: 3rem;
    display: grid;
    position: relative;
  }

  .fs-styleguide_item.is-stretch {
    justify-items: stretch;
  }

  .max-width-full {
    width: 100%;
    max-width: none;
  }

  .fs-styleguide_item-header {
    width: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding-bottom: 2rem;
  }

  .fs-styleguide_heading-large {
    font-size: 6rem;
  }

  .background-color-black {
    color: #f5f5f5;
    background-color: #000;
  }

  .z-index-1 {
    z-index: 1;
    position: relative;
  }

  .text-color-black {
    color: #000;
  }

  .text-color-grey {
    color: #66758a;
  }

  .fs-styleguide_2-col {
    width: 100%;
    grid-column-gap: 4rem;
    grid-row-gap: 4rem;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
    display: grid;
  }

  .fs-styleguide_2-col.is-align-start {
    align-items: start;
  }

  .form_message-success {
    padding: 1.25rem;
  }

  .fs-styleguide_row {
    grid-column-gap: 0.75rem;
    grid-row-gap: 0.75rem;
    flex-direction: row;
    grid-template-rows: auto;
    grid-template-columns: auto;
    grid-auto-columns: auto;
    grid-auto-flow: column;
    justify-content: flex-start;
    align-items: center;
    display: flex;
  }

  .heading-style-h3 {
    font-size: 2rem;
    font-weight: 700;
    line-height: 1.2;
  }

  .text-rich-text h1,
  .text-rich-text h2,
  .text-rich-text h3,
  .text-rich-text h4 {
    margin-top: 1.5rem;
    margin-bottom: 1rem;
  }

  .text-rich-text h5,
  .text-rich-text h6 {
    margin-top: 1.25rem;
    margin-bottom: 1rem;
  }

  .text-rich-text p {
    margin-bottom: 1rem;
  }

  .text-rich-text ul,
  .text-rich-text ol {
    margin-bottom: 1.5rem;
  }

  .container-small {
    width: 100%;
    max-width: 48rem;
    margin-left: auto;
    margin-right: auto;
  }

  .icon-height-small {
    height: 1rem;
  }

  .icon-1x1-medium {
    width: 2rem;
    height: 2rem;
  }

  .heading-style-h1 {
    font-size: 4rem;
    font-weight: 700;
    line-height: 1.1;
  }

  .padding-global {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .text-weight-normal {
    font-weight: 400;
  }

  .padding-section-small {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .max-width-small {
    width: 100%;
    max-width: 20rem;
  }

  .text-color-white {
    color: #fff;
  }

  .text-style-italic {
    font-style: italic;
  }

  .text-weight-medium {
    font-weight: 500;
  }

  .text-weight-medium.text-color-primary {
    color: #31ebf6;
  }

  .text-weight-medium.text-color-primary.action-link {
    font-weight: 600;
    text-decoration: none;
  }

  .overflow-hidden {
    overflow: hidden;
  }

  .fs-styleguide_section-header {
    width: 100%;
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
    border-bottom: 1px solid #000;
    grid-template-rows: auto;
    grid-template-columns: 1fr;
    grid-auto-columns: 1fr;
    padding-bottom: 3rem;
    line-height: 1.4;
    display: grid;
  }

  .text-size-tiny {
    font-size: 0.75rem;
  }

  .max-width-xxlarge {
    width: 100%;
    max-width: 80rem;
  }

  .fs-styleguide_1-col {
    width: 100%;
    grid-column-gap: 3rem;
    grid-row-gap: 3rem;
    grid-template-rows: auto;
    grid-template-columns: 1fr;
    grid-auto-columns: 1fr;
    display: grid;
  }

  .overflow-visible {
    overflow: visible;
  }

  .fs-styleguide_empty-box {
    z-index: -1;
    height: 3rem;
    min-width: 3rem;
    background-color: rgba(0, 115, 230, 0.1);
    border: 1px dashed #0073e6;
    position: relative;
  }

  .text-weight-light {
    font-weight: 300;
  }

  .fs-styleguide_heading-medium {
    font-size: 4rem;
  }

  .max-width-xsmall {
    width: 100%;
    max-width: 16rem;
  }

  .fs-styleguide_4-col {
    width: 100%;
    grid-column-gap: 4rem;
    grid-row-gap: 4rem;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-auto-columns: 1fr;
    display: grid;
  }

  .text-size-regular {
    font-size: 1rem;
  }

  .text-weight-xbold {
    font-weight: 800;
  }

  .text-align-right {
    text-align: right;
  }

  .text-weight-bold {
    font-weight: 700;
  }

  .max-width-medium {
    width: 100%;
    max-width: 32rem;
  }

  .fs-styleguide_item-wrapper {
    width: 100%;
    grid-column-gap: 3rem;
    grid-row-gap: 3rem;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    display: flex;
  }

  .max-width-large {
    width: 100%;
    max-width: 48rem;
  }

  .max-width-large.align-center {
    margin-left: auto;
    margin-right: auto;
  }

  .fs-styleguide_header-block {
    grid-column-gap: 2rem;
    grid-row-gap: 2rem;
    grid-template-rows: auto;
    grid-template-columns: 1fr;
    grid-auto-columns: 1fr;
    align-items: center;
    justify-items: start;
    display: grid;
  }

  .background-color-white {
    background-color: #fff;
  }

  .text-style-muted {
    opacity: 0.6;
  }

  .text-size-small {
    font-size: 0.875rem;
  }

  .text-size-small.text-weight-medium {
    font-weight: 500;
  }

  .heading-style-h4 {
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1.4;
  }

  .max-width-xlarge {
    width: 100%;
    max-width: 64rem;
  }

  .form_radio-icon {
    width: 0.875rem;
    height: 0.875rem;
    margin-top: 0;
    margin-left: 0;
    margin-right: 0.5rem;
  }

  .form_radio-icon.w--redirected-checked {
    width: 0.875rem;
    height: 0.875rem;
    border-width: 0.25rem;
  }

  .form_radio-icon.w--redirected-focus {
    width: 0.875rem;
    height: 0.875rem;
    box-shadow: 0 0 0.25rem 0 #3898ec;
  }

  .text-style-nowrap {
    white-space: nowrap;
  }

  .text-align-left {
    text-align: left;
  }

  .background-color-grey {
    background-color: #f5f5f5;
  }

  .form_input {
    min-height: 3rem;
    background-color: rgba(0, 0, 0, 0);
    border: 2px solid #f5f5f5;
    border-radius: 0.75rem;
    margin-bottom: 0.75rem;
    padding: 0.5rem 1rem;
    font-size: 1rem;
  }

  .form_input:focus {
    border-color: #000;
  }

  .form_input.is-text-area {
    min-height: 8rem;
    padding-top: 0.75rem;
  }

  .heading-style-h6 {
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.5;
  }

  .padding-section-large {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }

  .fs-styleguide_3-col {
    width: 100%;
    grid-column-gap: 4rem;
    grid-row-gap: 4rem;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-columns: 1fr;
    align-items: stretch;
    display: grid;
  }

  .fs-styleguide_3-col.is-align-start {
    align-items: start;
  }

  .text-style-link {
    color: #000;
    text-decoration: underline;
  }

  .text-size-large {
    font-size: 1.5rem;
  }

  .fs-styleguide_header {
    background-color: rgba(0, 0, 0, 0.05);
  }

  .heading-style-h2 {
    font-size: 3rem;
    font-weight: 700;
    line-height: 1.2;
  }

  .fs-styleguide_label {
    color: #fff;
    background-color: #0073e6;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0.25rem 0.75rem;
    font-weight: 600;
    line-height: 1.4;
    display: flex;
  }

  .fs-styleguide_label.is-tag {
    background-color: #be4aa5;
  }

  .fs-styleguide_label.is-hex {
    color: #000;
    background-color: #f5f5f5;
  }

  .fs-styleguide_version {
    z-index: 5;
    color: #000;
    font-weight: 500;
    text-decoration: none;
  }

  .heading-style-h5 {
    font-size: 1.125rem;
    font-weight: 600;
    line-height: 1.5;
  }

  .container-large {
    width: 100%;
    max-width: 80rem;
    margin-left: auto;
    margin-right: auto;
  }

  .icon-height-medium {
    height: 2rem;
  }

  .text-style-allcaps {
    text-transform: uppercase;
  }

  .overflow-scroll {
    overflow: scroll;
  }

  .form_message-error {
    margin-top: 0.75rem;
    padding: 0.75rem;
  }

  .icon-height-large {
    height: 3rem;
  }

  .text-align-center {
    text-align: center;
  }

  .form_component {
    margin-bottom: 0;
  }

  .max-width-xxsmall {
    width: 100%;
    max-width: 12rem;
  }

  .layer {
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0%;
    bottom: 0%;
    left: 0%;
    right: 0%;
  }

  .text-style-quote {
    border-left: 0.25rem solid #e2e2e2;
    margin-bottom: 0;
    padding: 0 1.25rem;
    font-size: 1.25rem;
    line-height: 1.5;
  }

  .align-center {
    margin-left: auto;
    margin-right: auto;
  }

  .button {
    color: #fff;
    text-align: center;
    background-color: #000;
    border-radius: 1rem;
    padding: 0.75rem 1.5rem;
    font-family: Inter, sans-serif;
    font-weight: 600;
  }

  .button.is-text {
    color: #000;
    background-color: rgba(0, 0, 0, 0);
    border: 2px solid rgba(0, 0, 0, 0);
  }

  .button.is-small {
    border-radius: 0.75rem;
    padding: 0.5rem 1.25rem;
  }

  .button.is-form-submit.margin-top.margin-medium.background-danger {
    background-color: red;
  }

  .button.is-large {
    padding: 1rem 2rem;
  }

  .button.is-secondary {
    color: #000;
    background-color: rgba(0, 0, 0, 0);
    border: 2px solid #000;
    border-bottom-width: 0.125rem;
  }

  .button.is-icon {
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    display: flex;
  }

  .button.gradient-background {
    background-image: linear-gradient(149deg, #31ebf6, #1ac4f4);
  }

  .button.is-navbar2-button {
    border-radius: 0.75rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .button.is-navbar2-button.gradient-background {
    font-size: 0.875rem;
    transition: all 0.4s;
  }

  .button.is-navbar2-button.gradient-background:hover {
    box-shadow: 0 5px 14px -2px rgba(139, 58, 241, 0.6);
  }

  .button.max-width-full {
    width: 100%;
    max-width: none;
  }

  .fs-styleguide_classes {
    grid-column-gap: 1px;
    grid-row-gap: 1px;
    grid-template-rows: auto;
    grid-template-columns: 1fr;
    grid-auto-columns: 1fr;
    display: grid;
  }

  .text-size-medium {
    font-size: 1.25rem;
  }

  .fs-styleguide_section {
    grid-column-gap: 6rem;
    grid-row-gap: 6rem;
    grid-template-rows: auto;
    grid-template-columns: 1fr;
    grid-auto-columns: 1fr;
    align-items: start;
    justify-items: start;
    display: grid;
  }

  .fs-styleguide_section.is-vertical {
    grid-column-gap: 4rem;
    grid-row-gap: 4rem;
    grid-template-columns: 1fr;
  }

  .button-group {
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
    flex-flow: wrap;
    justify-content: flex-start;
    align-items: center;
    display: flex;
  }

  .hide {
    display: none;
  }

  .padding-section-medium {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  .container-medium {
    width: 100%;
    max-width: 64rem;
    margin-left: auto;
    margin-right: auto;
  }

  .form_radio {
    flex-direction: row;
    align-items: center;
    margin-bottom: 0.5rem;
    padding-left: 0;
    display: flex;
  }

  .home-message_content-grid {
    grid-column-gap: 4rem;
    grid-row-gap: 4rem;
    grid-template-rows: auto;
    grid-template-columns: 1fr;
    grid-auto-columns: 1fr;
    align-content: center;
    justify-content: center;
    align-items: center;
    justify-items: start;
    display: grid;
  }

  .home-message_component {
    min-height: 100vh;
    color: #fff;
    padding: 3rem;
    display: flex;
  }

  .home-message_list {
    grid-column-gap: 1.5rem;
    grid-row-gap: 1.5rem;
    grid-template-rows: auto;
    grid-template-columns: 1fr;
    grid-auto-columns: 1fr;
    justify-items: start;
    display: grid;
  }

  .home-message_content {
    width: 100%;
    color: #000;
    background-color: rgba(0, 0, 0, 0.05);
    flex-direction: column;
    justify-content: center;
    align-self: stretch;
    align-items: stretch;
    display: flex;
  }

  .home-message_heading-block {
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    display: flex;
  }

  .home-message_item {
    grid-column-gap: 2rem;
    grid-row-gap: 2rem;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    display: flex;
  }

  .home-message_item-icon {
    width: 1.75rem;
    height: 1.75rem;
    color: #000;
    background-color: #fff;
    flex: none;
  }

  .home-message_logo {
    width: 4rem;
  }

  .fs-styleguide_spacing-all {
    display: none;
  }

  .fs-styleguide_color.is-1 {
    background-color: #000;
  }

  .fs-styleguide_color.is-2 {
    background-color: #f5f5f5;
  }

  .fs-styleguide_color.is-3 {
    background-color: #fff;
  }

  .navbar {
    z-index: 900;
    width: 100%;
    height: auto;
    min-height: 5rem;
    background-color: #fff;
    border: 1px #000;
    align-items: center;
    padding-left: 5%;
    padding-right: 5%;
    display: flex;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
  }

  .navbar-container {
    width: 100%;
    height: 100%;
    max-width: 64rem;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    grid-template-rows: auto;
    grid-template-columns: 0.25fr 0.5fr 0.25fr;
    grid-auto-columns: 1fr;
    justify-content: space-between;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    display: grid;
  }

  .navbar2_logo-link {
    padding-left: 0;
  }

  .navbar2_logo-link.w--current {
    max-width: 140px;
  }

  .navbar2_logo.show-mobile-only {
    display: none;
  }

  .navbar2_menu {
    justify-content: center;
    align-items: center;
    display: flex;
    position: static;
  }

  .navbar2_menu.is-page-height-tablet {
    border: 2px solid #f5f5f5;
    border-radius: 1rem;
    padding: 0.5rem;
  }

  .navbar2_link {
    color: #66758a;
    border-radius: 0.75rem;
    padding: 0.5rem 1rem;
    font-family: Inter, sans-serif;
    font-size: 0.875rem;
    font-weight: 500;
    transition: all 0.4s;
  }

  .navbar2_link:hover {
    background-color: #f5f5f5;
  }

  .navbar2_link.w--current {
    color: #fff;
    background-color: #294895;
    border-radius: 0.75rem;
    font-weight: 600;
  }

  .navbar2_dropdwn-toggle {
    padding: 0.5rem 2.5rem 0.5rem 1rem;
  }

  .dropdown-icon {
    width: 1rem;
    height: 1rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 1rem;
    display: flex;
    position: absolute;
    top: 0%;
    bottom: 0%;
    left: auto;
    right: 0%;
  }

  .navbar2_dropdown-list.w--open {
    background-color: #fff;
    border: 1px solid #000;
    padding: 0.5rem;
    position: absolute;
  }

  .navbar2_dropdown-link {
    padding: 0.5rem 1rem;
  }

  .navbar2_dropdown-link.w--current {
    color: #000;
  }

  .navbar2_button-wrapper {
    grid-column-gap: 0.5rem;
    grid-row-gap: 0.5rem;
    margin-left: 1rem;
    display: flex;
  }

  .navbar2_menu-button {
    padding: 0;
  }

  .profile-link {
    width: 40px;
    height: 40px;
    border: 2px solid #294895;
    border-radius: 500px;
    padding: 2px;
    transition: all 0.4s;
    overflow: hidden;
  }

  .profile-link:hover {
    padding: 0;
  }

  .profile-link.smaller {
    width: 24px;
    height: 24px;
    border-width: 1px;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .profile-link-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 500px;
  }

  .content-grid {
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    grid-template-rows: auto;
    grid-template-columns: 0.5fr 1.5fr;
    grid-auto-columns: 1fr;
    align-items: start;
    display: grid;
    position: relative;
  }

  .side-bar {
    position: relative;
    top: 0;
  }

  .main-content {
    padding-bottom: 3rem;
  }

  .sidebar-menu-grid {
    grid-column-gap: 0.5rem;
    grid-row-gap: 0.5rem;
    grid-template-rows: auto;
    grid-template-columns: 1fr;
  }

  .sidebar-menu-item {
    grid-column-gap: 0.5rem;
    grid-row-gap: 0.5rem;
    color: #66758a;
    align-items: center;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    font-weight: 500;
    text-decoration: none;
    display: flex;
    &.mobile {
      justify-content: center;
    }
  }

  .sidebar-menu-item.w--current {
    color: #000;
    font-weight: 600;
  }

  .sidemenu-icon {
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .page-title-group {
    grid-column-gap: 0.25rem;
    grid-row-gap: 0.25rem;
    flex-direction: column;
    padding-bottom: 2rem;
    display: flex;
  }

  .inner-content {
    grid-column-gap: 3rem;
    grid-row-gap: 3rem;
    flex-direction: column;
    display: flex;
  }

  ._3-col-grid {
    grid-column-gap: 1.25rem;
    grid-row-gap: 1.25rem;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr 1fr;
  }

  ._3-col-grid.art-grid._4-col {
    grid-row-gap: 2rem;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  ._3-col-grid.scroll-grid {
    overflow: scroll;
  }

  .content-action-card {
    min-height: 13.1875rem;
    color: #000;
    background-color: #f5f5f5;
    border-radius: 1rem;
    flex-direction: column;
    justify-content: flex-end;
    padding: 1.5rem 2rem;
    text-decoration: none;
    display: flex;
  }

  .content-action-card.distribute {
    color: #000;
    justify-content: space-between;
    text-decoration: none;
    transition: all 0.4s;
  }

  .action-icon {
    display: flex;
    position: absolute;
    top: 1rem;
    bottom: auto;
    left: auto;
    right: 1rem;
  }

  .action-icon.inner {
    justify-content: flex-end;
    position: relative;
    top: 0;
    right: 0;
  }

  .icon-flex {
    display: flex;
  }

  .icon-flex.right-align {
    justify-content: flex-end;
  }

  .mobile-menu-nav {
    display: none;
  }

  .sidebar-content {
    position: -webkit-sticky;
    position: sticky;
    top: 9rem;
  }

  .inner-content-group.gap-32px {
    grid-column-gap: 32px;
    grid-row-gap: 32px;
    flex-direction: column;
    display: flex;
  }

  .art-card {
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
    flex-direction: column;
    align-items: flex-start;
    display: flex;
    position: relative;
  }

  .art-image {
    object-fit: cover;
    border-radius: 1rem;
  }

  .art-card-context {
    grid-column-gap: 0.75rem;
    grid-row-gap: 0.75rem;
    justify-content: space-between;
    align-items: flex-start;
    display: flex;
  }

  .art-card-context.vertical {
    flex-direction: column;
  }

  .option-menu {
    background-color: #f5f5f5;
    border-radius: 0.5rem;
    flex: none;
    margin-left: 0;
    margin-right: 0;
  }

  .dropdown-toggle {
    padding: 0.6rem;
  }

  .dropdown-toggle.w--open {
    background-color: #e6e6e6;
    border-radius: 0.5rem;
  }

  .dropdown-list {
    top: 0%;
    bottom: auto;
    left: auto;
    right: 0%;
  }

  .dropdown-list.w--open {
    background-color: #fff;
    border-radius: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    top: 112%;
    box-shadow: 0 6px 20px -6px rgba(102, 117, 138, 0.17);
  }

  .dd-link {
    font-size: 0.875rem;
    font-weight: 500;
    transition: all 0.4s;
  }

  .dd-link:hover {
    background-color: #f5f5f5;
  }

  .dd-link.danger {
    color: #fd0000;
  }

  .divider {
    width: 100%;
    height: 2px;
    background-color: #f5f5f5;
  }

  .collection {
    grid-column-gap: 2rem;
    grid-row-gap: 2rem;
    flex-direction: column;
    display: flex;
  }

  .action-group {
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    display: flex;
  }

  .flex-box {
    grid-column-gap: 0.5rem;
    grid-row-gap: 0.5rem;
    color: #000;
    justify-content: flex-start;
    align-items: center;
    text-decoration: none;
    display: flex;
  }

  ._2-col-grid {
    grid-column-gap: 1.25rem;
    grid-row-gap: 1.25rem;
    grid-template-rows: auto;
  }

  ._2-col-grid.settings {
    grid-column-gap: 3rem;
    grid-row-gap: 3rem;
  }

  .pricing12_component {
    grid-column-gap: 2rem;
    grid-row-gap: 2rem;
    grid-template-rows: auto;
  }

  .pricing12_plan {
    border: 2px solid #f5f5f5;
    border-radius: 1rem;
    padding: 2rem;
  }

  .pricing12_divider {
    width: 100%;
    height: 1px;
    background-color: #000;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .pricing12_feature-list {
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
    grid-template-rows: auto;
    grid-template-columns: 1fr;
    grid-auto-columns: 1fr;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    display: grid;
  }

  .pricing12_feature {
    display: flex;
  }

  .pricing12_icon-wrapper {
    flex: none;
    align-self: flex-start;
    margin-right: 1rem;
  }

  .icon-1x1-xsmall {
    width: 1.5rem;
    height: 1.5rem;
  }

  .page-padding {
    padding-left: 5%;
    padding-right: 5%;
  }

  .table10_component {
    grid-column-gap: 5rem;
    grid-row-gap: 4rem;
    border-radius: 1rem;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
    padding-bottom: 0;
    position: relative;
    overflow: hidden;
  }

  .table10_header {
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
    border-top: 1px solid #000;
    border-left: 1px solid #000;
    border-right: 1px solid #000;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem;
    display: flex;
  }

  .table10_button-holder {
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
    display: flex;
  }

  .table10_content {
    border: 2px solid #f5f5f5;
    border-bottom-style: none;
    flex-direction: column;
    overflow: auto;
  }

  .table10_header-row {
    background-color: #f5f5f5;
    border-bottom: 2px solid #f5f5f5;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 0.5fr;
    grid-auto-columns: 1fr;
    display: flex;
  }

  .table10_column {
    min-height: 3.5rem;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    align-items: flex-start;
    padding: 1rem 1.5rem;
    display: flex;
  }

  .table10_column.is-width-large {
    max-width: 16rem;
    min-width: 12rem;
  }

  .table10_column.is-width-medium {
    max-width: 12rem;
    min-width: 8rem;
  }

  .table10_column.is-width-small {
    max-width: 8rem;
    min-width: 6rem;
  }

  .table10_header-link {
    color: #000;
    align-items: center;
    padding-right: 1rem;
    text-decoration: none;
    display: flex;
    position: relative;
  }

  .table10_header-link:hover {
    background-image: none;
    background-position: 100%;
    background-repeat: no-repeat;
  }

  .table10_column-button-wrapper {
    width: 5rem;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    padding-left: 1rem;
    padding-right: 1rem;
    display: flex;
  }

  .table10_item {
    border-bottom: 2px solid #f5f5f5;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 0.5fr;
    grid-auto-columns: 1fr;
    display: flex;
  }

  .table10_status-wrapper {
    color: #fff;
    background-color: #0d7e0a;
    border-radius: 0.5rem;
    justify-content: center;
    align-items: center;
    padding: 0.125rem 0.75rem;
    display: flex;
  }

  .table10_dropdown {
    justify-content: flex-end;
    position: static;
  }

  .table10_dropdown-toggle {
    width: 2rem;
    height: 2rem;
    grid-column-gap: 0.5rem;
    justify-content: center;
    align-items: center;
    padding: 0;
    font-weight: 500;
    text-decoration: none;
    display: flex;
  }

  .table10_dots-wrapper {
    grid-column-gap: 0.25rem;
    display: flex;
  }

  .table10_dot {
    width: 4px;
    height: 4px;
    background-color: #000;
    border-radius: 0.25rem;
  }

  .table10_dropdown-list.w--open {
    min-width: 9.5rem;
    background-color: #fff;
    border: 1px solid #000;
    padding: 0.5rem;
    right: 0;
  }

  .table10_dropdown-link {
    padding: 0.5rem 1rem;
  }

  .table10_dropdown-link.w--current {
    color: #000;
  }

  .table10_pagination {
    justify-content: center;
    margin-top: 2rem;
    display: flex;
    position: absolute;
    top: auto;
    bottom: 0%;
    left: 0%;
    right: 0%;
  }

  .table10_button {
    min-height: 2.5rem;
    min-width: 6.5rem;
    grid-column-gap: 0.5rem;
    grid-row-gap: 0.5rem;
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid #000;
    border-radius: 0;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 1.25rem;
    font-size: 1rem;
    line-height: 1.5;
    display: flex;
  }

  .table10_button.is-previous {
    justify-content: flex-start;
    margin-right: auto;
  }

  .table10_button.is-next {
    justify-content: flex-end;
    margin-left: auto;
  }

  .icon-embed-xxsmall {
    width: 1rem;
    height: 1rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .table10_pagination-page-button-wrapper {
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    position: absolute;
    top: 0%;
    bottom: 0%;
  }

  .table10_page-button {
    width: 2.5rem;
    height: 2.5rem;
    border: 1px solid rgba(0, 0, 0, 0);
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    padding: 0.5rem 1rem;
    display: flex;
  }

  .table10_page-button.w--current {
    border-color: #000;
  }

  .search-form {
    width: 100%;
    height: auto;
    max-width: 100%;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    grid-template-rows: auto;
    grid-template-columns: 1fr max-content;
    grid-auto-columns: 1fr;
    align-items: stretch;
    margin-bottom: 0;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    position: relative;
  }

  .form_input-2 {
    min-height: 3rem;
    background-color: rgba(0, 0, 0, 0);
    border: 2px solid #f5f5f5;
    border-radius: 0.75rem;
    margin-bottom: 0.75rem;
    padding: 0.5rem 1rem;
    font-size: 1rem;
  }

  .form_input-2:focus {
    border-color: #000;
  }

  .form_input-2.is-search-input {
    min-height: 4rem;
    border-radius: 1rem;
    margin-bottom: 0;
    padding-left: 3rem;
    font-size: 1rem;
  }

  .search-icon {
    width: 1.5rem;
    height: 100%;
    align-items: center;
    display: flex;
    position: absolute;
    top: auto;
    bottom: auto;
    left: 1rem;
    right: auto;
  }

  @media screen and (max-width: 991px) {
    .padding-xlarge {
      padding: 3rem;
    }

    .margin-xlarge {
      margin: 3rem;
    }

    .padding-xhuge {
      padding: 6rem;
    }

    .padding-xxhuge {
      padding: 8rem;
    }

    .padding-huge {
      padding: 5rem;
    }

    .margin-large {
      margin: 2.5rem;
    }

    .padding-xxlarge {
      padding: 4rem;
    }

    .padding-large {
      padding: 2.5rem;
    }

    .margin-huge {
      margin: 5rem;
    }

    .padding-medium {
      padding: 1.5rem;
    }

    .margin-xxlarge {
      margin: 4rem;
    }

    .margin-xhuge {
      margin: 6rem;
    }

    .margin-medium {
      margin: 1.5rem;
    }

    .margin-xxhuge {
      margin: 8rem;
    }

    .margin-horizontal {
      margin-top: 0;
      margin-bottom: 0;
    }

    .padding-top {
      padding-bottom: 0;
      padding-left: 0;
      padding-right: 0;
    }

    .margin-vertical {
      margin-left: 0;
      margin-right: 0;
    }

    .margin-bottom {
      margin-top: 0;
      margin-left: 0;
      margin-right: 0;
    }

    .padding-left {
      padding-top: 0;
      padding-bottom: 0;
      padding-right: 0;
    }

    .padding-vertical {
      padding-left: 0;
      padding-right: 0;
    }

    .padding-horizontal {
      padding-top: 0;
      padding-bottom: 0;
    }

    .margin-right {
      margin-top: 0;
      margin-bottom: 0;
      margin-left: 0;
    }

    .margin-top {
      margin-bottom: 0;
      margin-left: 0;
      margin-right: 0;
    }

    .margin-left {
      margin-top: 0;
      margin-bottom: 0;
      margin-right: 0;
    }

    .padding-right {
      padding-top: 0;
      padding-bottom: 0;
      padding-left: 0;
    }

    .padding-bottom {
      padding-top: 0;
      padding-left: 0;
      padding-right: 0;
    }

    .hide-tablet {
      display: none;
    }

    .fs-styleguide_2-col {
      grid-column-gap: 2rem;
      grid-row-gap: 2rem;
      grid-template-columns: 1fr;
    }

    .max-width-full-tablet {
      width: 100%;
      max-width: none;
    }

    .fs-styleguide_1-col {
      grid-column-gap: 2rem;
      grid-row-gap: 2rem;
    }

    .fs-styleguide_heading-medium {
      font-size: 3rem;
    }

    .fs-styleguide_4-col {
      grid-template-columns: 1fr;
    }

    .padding-section-large {
      padding-top: 6rem;
      padding-bottom: 6rem;
    }

    .fs-styleguide_3-col {
      grid-template-columns: 1fr;
    }

    .button.is-navbar2-button {
      height: 100%;
    }

    .fs-styleguide_section {
      grid-column-gap: 2.5rem;
      grid-template-columns: 1fr;
    }

    .padding-section-medium {
      padding-top: 4rem;
      padding-bottom: 4rem;
    }

    .navbar-container {
      grid-template-columns: 0.25fr 1fr;
      display: flex;
    }

    .navbar2_menu {
      -webkit-text-fill-color: inherit;
      background-color: #fff;
      background-clip: border-box;
      border-bottom: 1px solid #000;
      justify-content: center;
      padding: 1rem 5% 2.5rem;
      display: flex;
      position: absolute;
      overflow: auto;
    }

    .navbar2_menu.is-page-height-tablet {
      height: 100vh;
      border-style: none;
      border-radius: 0;
      padding-bottom: 15rem;
      padding-left: 5%;
      padding-right: 5%;
    }

    .navbar2_link {
      color: #000;
      text-align: left;
      background-color: #fff;
      border: 1px #000;
      padding: 0.5rem 0;
      font-size: 1.125rem;
      font-weight: 500;
    }

    .navbar2_link.w--current {
      color: #000;
      background-color: rgba(0, 0, 0, 0);
    }

    .navbar2_menu-dropdown {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 1.125rem;
      display: flex;
    }

    .navbar2_dropdwn-toggle {
      align-items: center;
      margin-left: 0;
      margin-right: 0;
      padding: 1rem 2rem 1rem 0;
      display: flex;
    }

    .dropdown-icon {
      margin-top: 0;
      margin-bottom: 0;
      margin-right: 0;
      top: auto;
      bottom: auto;
      left: auto;
      right: 0%;
    }

    .navbar2_dropdown-list {
      position: static;
      overflow: hidden;
    }

    .navbar2_dropdown-list.w--open {
      border-style: none;
      padding: 0;
      position: static;
    }

    .navbar2_dropdown-link {
      width: auto;
      text-align: center;
      padding: 0.75rem 0;
      font-size: 16px;
    }

    .navbar2_button-wrapper {
      align-items: center;
    }

    .navbar2_menu-button {
      padding: 0;
    }

    .navbar2_menu-button.w--open {
      background-color: rgba(0, 0, 0, 0);
    }

    .menu-icon2 {
      width: 48px;
      height: 48px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-right: -0.5rem;
      padding-bottom: 0;
      padding-right: 0;
      display: flex;
    }

    .menu-icon2_line-top {
      width: 24px;
      height: 2px;
      background-color: #000;
      padding-bottom: 0;
      padding-right: 0;
    }

    .menu-icon2_line-middle {
      width: 24px;
      height: 2px;
      background-color: #000;
      justify-content: center;
      align-items: center;
      margin-top: 6px;
      margin-bottom: 6px;
      padding-bottom: 0;
      padding-right: 0;
      display: flex;
    }

    .menu-icon_line-middle-inner {
      width: 4px;
      height: 0;
      padding-bottom: 0;
      padding-right: 0;
    }

    .menu-icon2_line-bottom {
      width: 24px;
      height: 2px;
      background-color: #000;
      padding-bottom: 0;
      padding-right: 0;
    }

    .content-grid {
      grid-column-gap: 3rem;
      grid-row-gap: 2rem;
      grid-template-columns: 1.5fr;
    }

    .mobile-menu-nav {
      border-top: 2px solid #f5f5f5;

      display: block;
      background-color: #ededed;
      position: absolute;
      height: 100vh;
      width: 100%;
      left: 0;
      right: 0;
      margin: 0;
      top: 60px;
    }

    .sidebar-content {
      display: none;
    }

    .table10_component {
      grid-row-gap: 4rem;
      grid-template-columns: 1fr;
      grid-auto-flow: row;
    }

    .table10_header-row,
    .table10_item {
      min-width: 72rem;
    }

    .table10_dropdown-list.w--open {
      right: 0;
    }
  }

  @media screen and (max-width: 767px) {
    h1 {
      font-size: 2.5rem;
    }

    h2 {
      font-size: 2rem;
    }

    h3 {
      font-size: 1.5rem;
    }

    h4 {
      font-size: 1.25rem;
    }

    h5 {
      font-size: 1rem;
    }

    h6 {
      font-size: 0.875rem;
    }

    .padding-xlarge {
      padding: 2rem;
    }

    .margin-xlarge {
      margin: 2rem;
    }

    .padding-xhuge {
      padding: 4rem;
    }

    .padding-xxhuge {
      padding: 4.5rem;
    }

    .padding-huge {
      padding: 3.5rem;
    }

    .margin-large {
      margin: 1.5rem;
    }

    .padding-xxlarge {
      padding: 3rem;
    }

    .padding-large {
      padding: 1.5rem;
    }

    .margin-huge {
      margin: 3.5rem;
    }

    .padding-medium {
      padding: 1.25rem;
    }

    .margin-xxlarge {
      margin: 3rem;
    }

    .margin-xhuge {
      margin: 4rem;
    }

    .margin-medium {
      margin: 1.25rem;
    }

    .margin-xxhuge {
      margin: 4.5rem;
    }

    .margin-horizontal {
      margin-top: 0;
      margin-bottom: 0;
    }

    .padding-top {
      padding-bottom: 0;
      padding-left: 0;
      padding-right: 0;
    }

    .margin-vertical {
      margin-left: 0;
      margin-right: 0;
    }

    .margin-bottom {
      margin-top: 0;
      margin-left: 0;
      margin-right: 0;
    }

    .padding-left {
      padding-top: 0;
      padding-bottom: 0;
      padding-right: 0;
    }

    .padding-vertical {
      padding-left: 0;
      padding-right: 0;
    }

    .padding-horizontal {
      padding-top: 0;
      padding-bottom: 0;
    }

    .margin-right {
      margin-top: 0;
      margin-bottom: 0;
      margin-left: 0;
    }

    .margin-top {
      margin-bottom: 0;
      margin-left: 0;
      margin-right: 0;
    }

    .margin-left {
      margin-top: 0;
      margin-bottom: 0;
      margin-right: 0;
    }

    .padding-right {
      padding-top: 0;
      padding-bottom: 0;
      padding-left: 0;
    }

    .padding-bottom {
      padding-top: 0;
      padding-left: 0;
      padding-right: 0;
    }

    .hide-mobile-landscape {
      display: none;
    }

    .fs-styleguide_heading-large {
      font-size: 3rem;
    }

    .heading-style-h3 {
      font-size: 1.5rem;
    }

    .heading-style-h1 {
      font-size: 2.5rem;
    }

    .padding-global {
      padding-left: 1.25rem;
      padding-right: 1.25rem;
    }

    .padding-section-small {
      padding-top: 2rem;
      padding-bottom: 2rem;
    }

    .fs-styleguide_section-header {
      font-size: 0.875rem;
    }

    .fs-styleguide_heading-medium {
      font-size: 2rem;
    }

    .max-width-full-mobile-landscape {
      width: 100%;
      max-width: none;
    }

    .heading-style-h4 {
      font-size: 1rem;
    }

    .text-style-nowrap {
      white-space: normal;
    }

    .heading-style-h6 {
      font-size: 0.75rem;
    }

    .padding-section-large {
      padding-top: 4rem;
      padding-bottom: 4rem;
    }

    .text-size-large {
      font-size: 1.25rem;
    }

    .heading-style-h2 {
      font-size: 2rem;
    }

    .fs-styleguide_version {
      font-size: 0.875rem;
    }

    .heading-style-h5 {
      font-size: 1.25rem;
    }

    .button.is-navbar2-button {
      padding: 0.55rem 1rem;
    }

    .padding-section-medium {
      padding-top: 3rem;
      padding-bottom: 3rem;
    }

    .home-message_component {
      padding: 0;
    }

    .home-message_content {
      border-radius: 0;
    }

    .navbar {
      min-height: 4rem;
    }

    .content-grid {
      grid-template-columns: 1fr;
    }

    ._3-col-grid {
      grid-template-columns: 1fr 1fr;
    }

    ._3-col-grid.art-grid._4-col {
      grid-template-columns: 1fr 1fr 1fr;
    }

    ._2-col-grid,
    .pricing12_component {
      grid-template-columns: 1fr;
    }

    .pricing12_plan {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }

    .table10_component {
      max-width: 510px;
      grid-row-gap: 3rem;
    }

    .table10_pagination-page-button-wrapper {
      display: none;
    }
  }

  @media screen and (max-width: 479px) {
    .margin-horizontal {
      margin-top: 0;
      margin-bottom: 0;
    }

    .padding-top {
      padding-bottom: 0;
      padding-left: 0;
      padding-right: 0;
    }

    .margin-vertical {
      margin-left: 0;
      margin-right: 0;
    }

    .margin-bottom {
      margin-top: 0;
      margin-left: 0;
      margin-right: 0;
    }

    .padding-left {
      padding-top: 0;
      padding-bottom: 0;
      padding-right: 0;
    }

    .padding-vertical {
      padding-left: 0;
      padding-right: 0;
    }

    .padding-horizontal {
      padding-top: 0;
      padding-bottom: 0;
    }

    .margin-right {
      margin-top: 0;
      margin-bottom: 0;
      margin-left: 0;
    }

    .margin-top {
      margin-bottom: 0;
      margin-left: 0;
      margin-right: 0;
    }

    .margin-left {
      margin-top: 0;
      margin-bottom: 0;
      margin-right: 0;
    }

    .padding-right {
      padding-top: 0;
      padding-bottom: 0;
      padding-left: 0;
    }

    .padding-bottom {
      padding-top: 0;
      padding-left: 0;
      padding-right: 0;
    }

    .text-color-grey.p-desc {
      font-size: 0.875rem;
    }

    .max-width-full-mobile-portrait {
      width: 100%;
      max-width: none;
    }

    .hide-mobile-portrait {
      display: none;
    }

    .heading-style-h5 {
      font-size: 1rem;
    }

    .navbar-container {
      grid-column-gap: 1rem;
      grid-row-gap: 1rem;
    }

    .navbar2_logo.show-mobile-only {
      max-width: 38px;
      display: block;
    }

    .navbar2_logo.desktop {
      display: none;
    }

    .navbar2_button-wrapper {
      align-items: center;
    }

    .content-grid {
      grid-template-columns: 1fr;
    }

    .page-title-group {
      grid-column-gap: 0rem;
      grid-row-gap: 0rem;
    }

    ._3-col-grid {
      grid-template-rows: auto;
      grid-template-columns: 1fr 1fr;
    }

    ._3-col-grid.art-grid,
    ._3-col-grid.art-grid._4-col {
      grid-template-columns: 1fr 1fr;
    }

    .content-action-card {
      min-height: 11rem;
      padding: 1.2rem 1rem;
    }

    .content-action-card.distribute {
      flex-direction: column;
      justify-content: space-between;
      padding-top: 1rem;
      padding-bottom: 1rem;
      position: relative;
    }

    .content-action-card.art-background {
      padding-top: 1rem;
      padding-bottom: 1rem;
    }

    .action-icon.inner {
      justify-content: flex-end;
      position: relative;
      top: 0;
      right: 0;
    }

    .art-card {
      grid-column-gap: 1rem;
      grid-row-gap: 1rem;
    }

    .art-card-context.alt {
      flex-direction: column;
    }

    .table10_component {
      max-width: 320px;
      grid-template-columns: 1fr;
    }

    .table10_header {
      flex-direction: column;
      align-items: flex-start;
    }

    .table10_header-row,
    .table10_item {
      min-width: auto;
    }

    .form_input-2.is-search-input {
      font-size: 0.9rem;
    }
  }

  #w-node-_1077fcef-8ece-dd8b-7ff6-718f772a75d0-03a36e81 {
    grid-area: span 1 / span 1 / span 1 / span 1;
  }

  #w-node-a98d4f2c-7ae9-d6fb-3a53-8c3891622a34-03a36e81 {
    grid-area: span 1 / span 2 / span 1 / span 2;
  }

  #w-node-a1c5a035-12ab-42f0-6923-08f41a798e59-03a36e81,
  #w-node-a1c5a035-12ab-42f0-6923-08f41a798e6d-03a36e81,
  #w-node-_80f0ae4c-c804-42b7-43c9-44e38a9444fa-8a9444f8,
  #w-node-_80f0ae4c-c804-42b7-43c9-44e38a9444fe-8a9444f8,
  #w-node-_80f0ae4c-c804-42b7-43c9-44e38a944506-8a9444f8,
  #w-node-_80f0ae4c-c804-42b7-43c9-44e38a944502-8a9444f8,
  #w-node-db0246c0-773f-f9a2-808a-1f7e4752c07d-8a9444f8,
  #w-node-_80f0ae4c-c804-42b7-43c9-44e38a94450a-8a9444f8,
  #w-node-_80f0ae4c-c804-42b7-43c9-44e38a94450e-8a9444f8 {
    grid-area: span 1 / span 1 / span 1 / span 1;
  }

  #w-node-_46a1a930-a937-37fe-bcca-d83df445cdc2-f445cdbd {
    justify-self: center;
  }

  #w-node-_46a1a930-a937-37fe-bcca-d83df445cdc9-f445cdbd {
    justify-self: end;
  }

  #w-node-_1077fcef-8ece-dd8b-7ff6-718f772a75d0-03a36e84 {
    grid-area: span 1 / span 1 / span 1 / span 1;
  }

  #w-node-_7930ba47-9a65-e00c-6d13-048e1e15859a-03a36e85 {
    align-self: center;
  }

  #w-node-_932177e5-3a26-8b65-a9a5-bd000b5ed4ab-03a36e85,
  #w-node-_932177e5-3a26-8b65-a9a5-bd000b5ed4bb-03a36e85,
  #w-node-_9ca3393e-9b27-9320-3b48-5f25c6958d54-03a36e85,
  #w-node-_5b547d7a-9e59-936e-b221-0a3db855dfb7-03a36e85,
  #w-node-e9202f30-49a0-ef62-b313-75aa0238dd81-03a36e85,
  #w-node-_63c662a4-7beb-9c96-a26e-299f269b8f72-03a36e85,
  #w-node-_6b9625e3-6b79-35a4-9db1-ee20d7bec3cd-03a36e85,
  #w-node-_5483cb48-53fe-ce08-f546-521d7d333d44-03a36e85,
  #w-node-_96f04ea6-c13b-a3cf-feef-bc8535c471b6-03a36e85,
  #w-node-_3027dc0d-6304-0e65-4913-69b6ee1fe2ea-03a36e85,
  #w-node-bda037c9-00a7-d86f-eecb-4829d809476f-03a36e85,
  #w-node-ad2b29a3-02bc-ef5d-c5e1-2610d75ff42f-03a36e85,
  #w-node-b17fa2c8-8ff8-b5a5-5668-47c24cd04ed1-03a36e85,
  #w-node-_54805657-5ae1-f420-d3fb-04496ea343dc-03a36e85,
  #w-node-fabd1f98-d2a4-2a35-b286-eccfc9ea30c9-03a36e85,
  #w-node-_55c6f595-efe1-d0b3-7477-276c310395ae-03a36e85,
  #w-node-_1077fcef-8ece-dd8b-7ff6-718f772a75d0-03a36e86,
  #w-node-_28b883b2-d8cc-9186-0b17-1536c2c8f576-03a36e86,
  #w-node-_80d5bba1-4815-f10a-3228-f43f7e124ce6-03a36e86 {
    grid-area: span 1 / span 1 / span 1 / span 1;
  }

  #w-node-_7930ba47-9a65-e00c-6d13-048e1e15859a-03a36e87 {
    align-self: center;
  }

  #w-node-_7d61c8b2-985d-8539-6336-be64f80d4230-03a36e87,
  #w-node-c3aaaf50-3b64-6657-c92f-ac043a043267-03a36e87,
  #w-node-_4fa1b5c2-aeea-50bd-fe46-7d4e1b331e70-03a36e87,
  #w-node-dfdb4c14-af41-da2b-56e8-657c1a55fd41-03a36e87,
  #w-node-_45c2b974-a0d5-f17d-2d44-9ee1b86dbfb2-03a36e87,
  #w-node-_94b2bcd4-9cb8-217f-c449-67cdb2263d2f-03a36e87,
  #w-node-d7e79e04-2b0a-008d-903f-b666d4d90b4e-03a36e87,
  #w-node-b5a3fe56-0373-02a1-712f-4d999791d8fb-03a36e87,
  #w-node-_1077fcef-8ece-dd8b-7ff6-718f772a75d0-03a36e88,
  #w-node-_7f3b47f7-2383-eb66-2352-e4a2b1ac2aa4-03a36e88,
  #w-node-_26570ec4-4c7c-0196-e758-ad4fcffc5a60-03a36e88,
  #w-node-_36b3708c-af23-7b59-f171-27ee1e691e81-03a36e88,
  #w-node-_64d22557-0e7a-dfa0-6fd5-9296011a036e-03a36e88,
  #w-node-_46e4a434-9e2b-21ac-b609-18d8378cfa08-03a36e88,
  #w-node-_27b7f9f0-d488-450b-2d61-8c3dcffed869-03a36e88,
  #w-node-_1077fcef-8ece-dd8b-7ff6-718f772a75d0-03a36e89,
  #w-node-_2f67111e-49cc-7fdd-87ba-7e37f4283376-03a36e89 {
    grid-area: span 1 / span 1 / span 1 / span 1;
  }

  #w-node-_32eadc24-e391-a089-abe0-fb5b6627ca17-03a36e8a,
  #w-node-_32eadc24-e391-a089-abe0-fb5b6627ca1c-03a36e8a,
  #w-node-_32eadc24-e391-a089-abe0-fb5b6627ca21-03a36e8a,
  #w-node-_32eadc24-e391-a089-abe0-fb5b6627ca26-03a36e8a,
  #w-node-_32eadc24-e391-a089-abe0-fb5b6627ca2c-03a36e8a,
  #w-node-_32eadc24-e391-a089-abe0-fb5b6627ca32-03a36e8a,
  #w-node-_32eadc24-e391-a089-abe0-fb5b6627ca38-03a36e8a {
    justify-self: start;
  }

  #w-node-_32eadc24-e391-a089-abe0-fb5b6627cb45-03a36e8a,
  #w-node-_32eadc24-e391-a089-abe0-fb5b6627cb5e-03a36e8a {
    grid-area: span 1 / span 1 / span 1 / span 1;
  }

  #w-node-_32eadc24-e391-a089-abe0-fb5b6627cb61-03a36e8a {
    justify-self: start;
  }

  #w-node-_32eadc24-e391-a089-abe0-fb5b6627cb63-03a36e8a {
    grid-area: span 1 / span 1 / span 1 / span 1;
  }

  #w-node-_32eadc24-e391-a089-abe0-fb5b6627cb66-03a36e8a {
    justify-self: start;
  }

  #w-node-_32eadc24-e391-a089-abe0-fb5b6627cbb8-03a36e8a,
  #w-node-_32eadc24-e391-a089-abe0-fb5b6627cbc2-03a36e8a,
  #w-node-_32eadc24-e391-a089-abe0-fb5b6627cbc3-03a36e8a {
    grid-area: span 1 / span 1 / span 1 / span 1;
  }

  #w-node-_32eadc24-e391-a089-abe0-fb5b6627cc31-03a36e8a,
  #w-node-_32eadc24-e391-a089-abe0-fb5b6627cc36-03a36e8a,
  #w-node-_32eadc24-e391-a089-abe0-fb5b6627cc3b-03a36e8a,
  #w-node-_32eadc24-e391-a089-abe0-fb5b6627cc40-03a36e8a,
  #w-node-_32eadc24-e391-a089-abe0-fb5b6627cc45-03a36e8a,
  #w-node-_32eadc24-e391-a089-abe0-fb5b6627cc4a-03a36e8a,
  #w-node-_32eadc24-e391-a089-abe0-fb5b6627cc54-03a36e8a,
  #w-node-_32eadc24-e391-a089-abe0-fb5b6627cc59-03a36e8a,
  #w-node-_32eadc24-e391-a089-abe0-fb5b6627cc5e-03a36e8a,
  #w-node-_32eadc24-e391-a089-abe0-fb5b6627cc63-03a36e8a,
  #w-node-_32eadc24-e391-a089-abe0-fb5b6627cc68-03a36e8a,
  #w-node-_32eadc24-e391-a089-abe0-fb5b6627cc6d-03a36e8a,
  #w-node-_32eadc24-e391-a089-abe0-fb5b6627cc72-03a36e8a,
  #w-node-_32eadc24-e391-a089-abe0-fb5b6627cc77-03a36e8a,
  #w-node-_32eadc24-e391-a089-abe0-fb5b6627cc7c-03a36e8a,
  #w-node-_32eadc24-e391-a089-abe0-fb5b6627cc81-03a36e8a,
  #w-node-_32eadc24-e391-a089-abe0-fb5b6627cc86-03a36e8a {
    justify-self: start;
  }

  #w-node-_32eadc24-e391-a089-abe0-fb5b6627cc8a-03a36e8a {
    grid-area: span 1 / span 1 / span 1 / span 1;
  }

  #w-node-_32eadc24-e391-a089-abe0-fb5b6627cc8b-03a36e8a,
  #w-node-_32eadc24-e391-a089-abe0-fb5b6627cc90-03a36e8a,
  #w-node-_32eadc24-e391-a089-abe0-fb5b6627cc95-03a36e8a,
  #w-node-_32eadc24-e391-a089-abe0-fb5b6627cc9a-03a36e8a,
  #w-node-_32eadc24-e391-a089-abe0-fb5b6627ccaf-03a36e8a,
  #w-node-_32eadc24-e391-a089-abe0-fb5b6627ccb4-03a36e8a,
  #w-node-_32eadc24-e391-a089-abe0-fb5b6627ccb9-03a36e8a,
  #w-node-_32eadc24-e391-a089-abe0-fb5b6627ccbe-03a36e8a,
  #w-node-_32eadc24-e391-a089-abe0-fb5b6627ccc3-03a36e8a,
  #w-node-_32eadc24-e391-a089-abe0-fb5b6627ccc8-03a36e8a,
  #w-node-_32eadc24-e391-a089-abe0-fb5b6627ccd2-03a36e8a,
  #w-node-_32eadc24-e391-a089-abe0-fb5b6627ccd7-03a36e8a,
  #w-node-_32eadc24-e391-a089-abe0-fb5b6627ccdc-03a36e8a,
  #w-node-_32eadc24-e391-a089-abe0-fb5b6627cce1-03a36e8a,
  #w-node-_32eadc24-e391-a089-abe0-fb5b6627cce6-03a36e8a,
  #w-node-_32eadc24-e391-a089-abe0-fb5b6627cceb-03a36e8a,
  #w-node-_32eadc24-e391-a089-abe0-fb5b6627ccf0-03a36e8a,
  #w-node-_32eadc24-e391-a089-abe0-fb5b6627ccf5-03a36e8a,
  #w-node-_32eadc24-e391-a089-abe0-fb5b6627ccfa-03a36e8a,
  #w-node-_32eadc24-e391-a089-abe0-fb5b6627ccff-03a36e8a,
  #w-node-_32eadc24-e391-a089-abe0-fb5b6627cd04-03a36e8a {
    justify-self: start;
  }

  #w-node-_32eadc24-e391-a089-abe0-fb5b6627cd08-03a36e8a {
    grid-area: span 1 / span 1 / span 1 / span 1;
  }

  #w-node-_32eadc24-e391-a089-abe0-fb5b6627cd09-03a36e8a,
  #w-node-_32eadc24-e391-a089-abe0-fb5b6627cd0e-03a36e8a,
  #w-node-_32eadc24-e391-a089-abe0-fb5b6627cd13-03a36e8a,
  #w-node-_32eadc24-e391-a089-abe0-fb5b6627cd18-03a36e8a {
    justify-self: start;
  }

  #w-node-_32eadc24-e391-a089-abe0-fb5b6627cd6b-03a36e8a {
    grid-area: span 1 / span 1 / span 1 / span 1;
  }

  #w-node-_32eadc24-e391-a089-abe0-fb5b6627cdc7-03a36e8a {
    justify-self: start;
  }

  #w-node-_1077fcef-8ece-dd8b-7ff6-718f772a75d0-03a36e8b {
    grid-area: span 1 / span 1 / span 1 / span 1;
  }

  #w-node-_816d80a4-e9f3-8812-497b-f122bb9c696d-03a36e8b,
  #w-node-_816d80a4-e9f3-8812-497b-f122bb9c6972-03a36e8b,
  #w-node-_816d80a4-e9f3-8812-497b-f122bb9c6977-03a36e8b,
  #w-node-_816d80a4-e9f3-8812-497b-f122bb9c6990-03a36e8b,
  #w-node-_816d80a4-e9f3-8812-497b-f122bb9c6995-03a36e8b,
  #w-node-_816d80a4-e9f3-8812-497b-f122bb9c699a-03a36e8b,
  #w-node-_816d80a4-e9f3-8812-497b-f122bb9c699f-03a36e8b,
  #w-node-_816d80a4-e9f3-8812-497b-f122bb9c69a4-03a36e8b {
    align-self: start;
  }

  @media screen and (max-width: 767px) {
    #w-node-a98d4f2c-7ae9-d6fb-3a53-8c3891622a34-03a36e81 {
      grid-area: span 1 / span 1 / span 1 / span 1;
    }
  }

  /* Make text look crisper and more legible in all browsers */
  body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
  }
  /* Focus state style for keyboard navigation for the focusable elements */
  *[tabindex]:focus-visible,
  input[type="file"]:focus-visible {
    outline: 0.125rem solid #4d65ff;
    outline-offset: 0.125rem;
  }
  /* Get rid of top margin on first element in any rich text element */
  .w-richtext > :not(div):first-child,
  .w-richtext > div:first-child > :first-child {
    margin-top: 0 !important;
  }
  /* Get rid of bottom margin on last element in any rich text element */
  .w-richtext > :last-child,
  .w-richtext ol li:last-child,
  .w-richtext ul li:last-child {
    margin-bottom: 0 !important;
  }
  /* Prevent all click and hover interaction with an element */
  .pointer-events-off {
    pointer-events: none;
  }
  /* Enables all click and hover interaction with an element */
  .pointer-events-on {
    pointer-events: auto;
  }
  /* Create a class of .div-square which maintains a 1:1 dimension of a div */
  .div-square::after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
  /* Make sure containers never lose their center alignment */
  .container-medium,
  .container-small,
  .container-large {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  /* 
  Make the following elements inherit typography styles from the parent and not have hardcoded values. 
  Important: You will not be able to style for example "All Links" in Designer with this CSS applied.
  Uncomment this CSS to use it in the project. Leave this message for future hand-off.
  */
  /*
  a,
  .w-input,
  .w-select,
  .w-tab-link,
  .w-nav-link,
  .w-dropdown-btn,
  .w-dropdown-toggle,
  .w-dropdown-link {
    color: inherit;
    text-decoration: inherit;
    font-size: inherit;
  }
  */
  /* Apply "..." after 3 lines of text */
  .text-style-3lines {
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  /* Apply "..." after 2 lines of text */
  .text-style-2lines {
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  /* Adds inline flex display */
  .display-inlineflex {
    display: inline-flex;
  }
  /* These classes are never overwritten */
  .hide {
    display: none !important;
  }
  @media screen and (max-width: 991px) {
    .hide,
    .hide-tablet {
      display: none !important;
    }
  }
  @media screen and (max-width: 767px) {
    .hide-mobile-landscape {
      display: none !important;
    }
  }
  @media screen and (max-width: 479px) {
    .hide-mobile {
      display: none !important;
    }
  }
  .margin-0 {
    margin: 0rem !important;
  }
  .padding-0 {
    padding: 0rem !important;
  }
  .spacing-clean {
    padding: 0rem !important;
    margin: 0rem !important;
  }
  .margin-top {
    margin-right: 0rem !important;
    margin-bottom: 0rem !important;
    margin-left: 0rem !important;
  }
  .padding-top {
    padding-right: 0rem !important;
    padding-bottom: 0rem !important;
    padding-left: 0rem !important;
  }
  .margin-right {
    margin-top: 0rem !important;
    margin-bottom: 0rem !important;
    margin-left: 0rem !important;
  }
  .padding-right {
    padding-top: 0rem !important;
    padding-bottom: 0rem !important;
    padding-left: 0rem !important;
  }
  .margin-bottom {
    margin-top: 0rem !important;
    margin-right: 0rem !important;
    margin-left: 0rem !important;
  }
  .padding-bottom {
    padding-top: 0rem !important;
    padding-right: 0rem !important;
    padding-left: 0rem !important;
  }
  .margin-left {
    margin-top: 0rem !important;
    margin-right: 0rem !important;
    margin-bottom: 0rem !important;
  }
  .padding-left {
    padding-top: 0rem !important;
    padding-right: 0rem !important;
    padding-bottom: 0rem !important;
  }
  .margin-horizontal {
    margin-top: 0rem !important;
    margin-bottom: 0rem !important;
  }
  .padding-horizontal {
    padding-top: 0rem !important;
    padding-bottom: 0rem !important;
  }
  .margin-vertical {
    margin-right: 0rem !important;
    margin-left: 0rem !important;
  }
  .padding-vertical {
    padding-right: 0rem !important;
    padding-left: 0rem !important;
  }
}
