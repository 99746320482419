.auth-wrapper {
  body {
    color: #fff;
    font-family: Outfit, sans-serif;
    font-size: 16px;
    font-weight: 300;
    line-height: 1.2;
  }

  h1 {
    width: 100%;
    max-width: 510px;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 50px;
    font-weight: 400;
    line-height: 1.2;
  }

  h2 {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 30px;
    font-weight: 600;
    line-height: 1.2;
  }

  p {
    margin-bottom: 0;
  }

  .section {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .container {
    min-height: 100vh;
    background-color: #fff;
    display: flex;
  }

  .left {
    width: 50%;
    background-image: url("./images/background.jpg");
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    justify-content: center;
    padding: 12% 20px 20px;
    display: none;
    position: relative;
  }

  .right {
    width: 100%;
    color: #000;
    background-color: #fff;
    background-image: url("./images/Logo_stacked_white.png");
    background-position: -100% 0;
    background-repeat: no-repeat;
    background-size: auto;
    padding: 12% 0 20px;
    font-family: Gilroy Medium, sans-serif;
  }

  .left-blur {
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    mix-blend-mode: darken;
    background-color: rgba(9, 23, 28, 0.1);
    position: absolute;
    top: 0%;
    bottom: 0%;
    left: 0%;
    right: 0%;
  }

  .z-positive {
    z-index: 10;
    margin-top: -49px;
    position: relative;
  }

  .logo-link {
    max-width: 120px;
    margin-bottom: 60px;
  }

  .right-wrap {
    width: 100%;
    max-width: 370px;
    background-color: rgba(101, 104, 169, 0);
    border-radius: 20px;
    margin-left: auto;
    margin-right: auto;
    padding: 0;
  }

  .mb-25 {
    margin-bottom: 25px;
  }

  .form-field {
    margin-bottom: 30px;
  }

  .button {
    width: 100%;
    height: 50px;
    color: #fff;
    background-color: #294895;
    border: 1px solid #294895;
    border-radius: 10px;
    margin-top: 50px;
    margin-bottom: 10px;
    font-size: 20px;
    font-weight: 500;
    transition: background-color 0.2s, color 0.2s;
    display: block;
  }

  .button:hover {
    color: #294895;
    background-color: rgba(0, 0, 0, 0);
  }

  .label {
    margin-bottom: 10px;
    font-family: Gilroy Bold, sans-serif;
    font-size: 16px;
    font-weight: 500;
  }

  .field {
    height: 50px;
    color: #fff;
    background-color: #000;
    background-image: url("./images/email.svg");
    background-position: 17px;
    background-repeat: no-repeat;
    background-size: auto;
    border: 1px solid #294895;
    border-radius: 10px;
    margin-bottom: 0;
    padding-bottom: 10px;
    padding-left: 55px;
    font-family: Gilroy Bold, sans-serif;
    font-size: 16px;
    transition: border 0.2s;
  }
  .field.nonsvg {
    background-image: unset;
    padding-left: 20px;
  }

  .field:focus {
    border-color: #294895;
  }

  .field::-ms-input-placeholder {
    font-size: 16px;
  }

  .field::placeholder {
    font-size: 16px;
  }

  .field.password {
    color: #fff;
    background-image: url("./images/lock.svg");
    border-color: #294895;
    padding-bottom: 7px;
    padding-right: 50px;
    font-size: 16px;
    font-weight: 500;
  }

  .eye-icon {
    cursor: pointer;
    position: absolute;
    top: 50%;
    bottom: auto;
    left: auto;
    right: 17px;
    transform: translate(0, -50%);
  }

  .eye-icon.closed {
    display: none;
  }

  .field-wrap {
    position: relative;
  }

  .link {
    color: #294895;
    margin-top: 3px;
    text-decoration: none;
    display: block;
  }

  .label-p {
    text-align: center;
  }

  .mb-30 {
    text-align: center;
    margin-top: 10px;
    margin-bottom: 30px;
  }

  .button-google {
    height: 50px;
    grid-column-gap: 12px;
    color: #294895;
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid #294895;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 20px;
    font-weight: 600;
    text-decoration: none;
    transition: opacity 0.2s, box-shadow 0.2s;
    display: flex;
  }

  .button-google:hover {
    opacity: 0.8;
    box-shadow: 0 1px 20px rgba(0, 0, 0, 0.1);
  }

  .ms-iframe {
    z-index: 2147483647;
    height: 70px;
    justify-content: flex-end;
    align-items: flex-end;
    display: flex;
    position: fixed;
    top: auto;
    bottom: 0%;
    left: 0%;
    right: auto;
  }

  .ms-iframe-embed {
    width: 180px;
    height: 70px;
    justify-content: flex-end;
    align-items: flex-end;
    display: flex;
    position: relative;
    overflow: visible;
  }

  .ms-iframe-footer-embed {
    width: 100%;
    height: 350px;
    background-color: #d1e7fb;
    background-image: url("./images/noise.png");
    background-position: 0 0;
    background-size: 200px;
    justify-content: flex-end;
    align-items: flex-end;
    margin-bottom: 0;
    display: block;
    overflow: hidden;
  }

  .hidden-embed {
    z-index: -1;
    height: 0;
    max-height: 0;
    opacity: 0;
    position: fixed;
  }

  .image {
    min-width: 200px;
  }

  .heading {
    color: #fff;
    margin-top: -575px;
    margin-left: 48px;
    line-height: 1.6;
  }

  .image-2 {
    min-width: 600px;
  }

  .image-3 {
    width: 40%;
    margin-left: 30%;
    margin-right: 30%;
  }

  @media screen and (max-width: 991px) {
    .left {
      max-width: none;
      display: none;
    }

    .right {
      width: 100%;
      justify-content: center;
      align-items: center;
      padding-top: 20px;
      display: flex;
    }
  }

  @media screen and (max-width: 479px) {
    .container {
      min-width: 300px;
    }
  }
  @media screen and (max-width: 1300px) {
    .right {
      background-position: 50% 0;
    }
  }
  @media screen and (min-width: 1800px) {
    .right {
      background-position: 50% 0;
    }
  }

  @font-face {
    font-family: "Gilroy Medium";
    src: url("./fonts/Gilroy-Medium.woff") format("woff");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: "Gilroy Bold";
    src: url("./fonts/Gilroy-Bold.woff") format("woff");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }
}
